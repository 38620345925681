import api from '@/services/api';
import Vue from 'vue';
import { make } from 'vuex-pathify';
import { arrayToObject } from '@/assets/js/utility';
import { getFormFlat } from '@/assets/js/attributesUtility';

const state = {
  migrationInterval: undefined,
  settings: [],
  settingsMetadata: {},
};

const mutations = {
  ...make.mutations(state),
  SET_MIGRATION_INTERVAL(state, { interval = 5000, action } = {}) {
    Vue.set(state, 'migrationInterval', setInterval(action, interval));
  },
  CLEAR_MIGRATION_INTERVAL(state) {
    if (state.migrationInterval) clearInterval(state.migrationInterval);
    Vue.set(state, 'migrationInterval', undefined);
  },
};

const actions = {
  async getSettings({ commit }) {
    const r = await api.get('settings');
    commit('SET_SETTINGS', Object.freeze(r.data.data));
  },
  async getSettingsMetadata({ commit, dispatch }) {
    const promises = [dispatch('getProjectsMetadata'), dispatch('getDataSourcesMetadata'), dispatch('getFormPrints')];
    const [projects, dataSources, formPrints] = await Promise.all(promises);
    const layers = await dispatch('getLayersMetadata', dataSources);
    commit('SET_SETTINGS_METADATA', Object.freeze({ projects, dataSources, layers, formPrints }));
  },
  async getProjectsMetadata() {
    const r = await api.get('projects');
    return arrayToObject(
      r.data.data
        .filter(project => project.id || project.id === 0)
        .map(project => {
          return { value: project.id, text: project.name };
        }),
      'value'
    );
  },
  async getDataSourcesMetadata() {
    const r = await api.get('datasources/metadata');
    return arrayToObject(
      r.data.data
        .filter(dataSource => dataSource.scope === 'core')
        .map(dataSource => {
          return {
            value: dataSource.name,
            text: dataSource.verbose_name,
            geometryType: dataSource.geometry_type,
            attributes: dataSource.attributes_schema.attributes,
          };
        }),
      'value'
    );
  },
  async getLayersMetadata(state, dataSourcesMetadata = {}) {
    const r = await api.get('layers');
    return arrayToObject(
      r.data.data
        .filter(layer => layer.layer_scope === 'core' && layer.type === 'features_layer')
        .map(layer => {
          return {
            value: layer.id,
            text: layer.name,
            dataSourceName: layer.data_source_name,
            attributes: getFormFlat(
              layer.form_schema?.elements || [],
              dataSourcesMetadata[layer.data_source_name]?.attributes || []
            ).map(attribute => {
              return {
                value: attribute.attribute,
                text: attribute.label,
                dataType: attribute.dataType,
                icon: attribute.icon,
                unit: attribute.unit,
              };
            }),
          };
        }),
      'value'
    );
  },
  async getFormPrints() {
    return (await api.post('form_print/forms')).data.data;
  },
  async migrateLayersAndDataSourcesData() {
    await api.post('layers');
  },
  async migrateSettingsData({ dispatch }) {
    await dispatch('migrateLayersAndDataSourcesData');
  },
  async getMigrationStatus() {
    const r = await api.post('layers/status');
    return r.status;
  },
  async saveSettings({ dispatch }, payload) {
    await api.put('settings', payload);
    await dispatch('getSettings');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
