<template>
  <v-app id="app" class="fill-height">
    <v-main>
      <template v-if="isFetched">
        <router-view />
      </template>
      <main-loader v-else />
      <the-snackbar />
    </v-main>
  </v-app>
</template>

<script>
import { call } from 'vuex-pathify';
import MainLoader from '@/components/shared/MainLoader';
import TheSnackbar from '@/components/snackbar/TheSnackbar';
export default {
  name: 'App',
  components: { MainLoader, TheSnackbar },
  computed: {
    currentLng() {
      return this.$i18n.locale;
    },
  },
  data: () => ({
    isFetched: false,
  }),
  methods: {
    getSettings: call('admin/getSettings'),
    async init() {
      document.title = this.$_config.appName;
      const lng =
        this.$_config.languages.find(language => language === localStorage.getItem('lng')) ||
        this.$_config.languages.find(language => window.navigator.language.startsWith(language)) ||
        this.$_config.defaultLanguage;
      this.$i18n.locale = lng;
      this.$vuetify.lang.current = lng;
      this.$dayjs.locale(lng);
      await this.getSettings();
      this.isFetched = true;
    },
  },
  watch: {
    currentLng(nV) {
      if (!nV) return;
      localStorage.setItem('lng', nV);
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  overflow-y: hidden;
}
.row + .row {
  margin-top: 0 !important;
}
#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  * {
    scrollbar-color: #8f8f8f white;
    scrollbar-width: thin;
  }
}
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #8f8f8f;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background: #787878;
}
::-webkit-scrollbar-thumb:active {
  background: #6e6e6e;
}
::-webkit-scrollbar-track {
  background: #ededed;
  border: 0px none #ffffff;
  border-radius: 3px;
}
::-webkit-scrollbar-track:hover {
  background: #ededed;
}
::-webkit-scrollbar-track:active {
  background: #ededed;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

// Global classes
.cursor-pointer {
  cursor: pointer !important;
}
.fill-width {
  width: 100% !important;
}
@for $i from 8 through 24 {
  .font-size-#{$i} {
    font-size: 0px + $i !important;
  }
}
</style>
