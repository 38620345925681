const getFlatGroupsLayers = (elements = []) => {
  const groups = [];
  const layers = [];
  for (const element of elements) {
    if (element.layers) {
      groups.push(element);
      for (const layer of element.layers) {
        layers.push(layer);
      }
    } else {
      layers.push(element);
    }
  }
  return { groups, layers };
};
export { getFlatGroupsLayers };
