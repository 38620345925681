import api from '@/services/api';
import { make } from 'vuex-pathify';

const state = {
  token:
    localStorage.getItem('token') === 'null'
      ? JSON.parse(localStorage.getItem('token'))
      : localStorage.getItem('token'),
};

const mutations = {
  ...make.mutations(state),
  SET_TOKEN(state, value) {
    state.token = value;
    localStorage.setItem('token', value);
  },
};

const actions = {
  async checkToken() {
    try {
      await api.get('check_token');
      return true;
    } catch {
      return false;
    }
  },
  async login({ commit }, payload) {
    const r = await api.post('login', payload);
    commit('SET_TOKEN', r.data.token);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
