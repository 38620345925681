const project = {
  id: 1,
  layers: [
    {
      id: 1,
      layers: [
        {
          geometry_type: 'multipolygon',
          labels_visible: false,
          name: 'Warstwa 1',
          opacity: 1,
          style: {
            'fill-color': '#910E4A',
            'fill-opacity': 1,
            'fill-outline-color': '#000000',
            'fill-outline-opacity': 1,
            labels: {
              attributes: {},
              'font-color': '#000000',
              'font-size': 12,
              'font-weight': 'normal',
              minzoom: 1,
              'offset-x': 0,
              'offset-y': 0,
              'stroke-color': '#000000',
              'stroke-visible': false,
              'stroke-width': 3,
            },
            'line-dash': [],
            'line-width': 1,
            minzoom: 1,
          },
          type: 'features_layer',
          visible: true,
        },
        {
          geometry_type: 'multilinestring',
          labels_visible: false,
          name: 'Warstwa 2',
          opacity: 1,
          style: {
            'fill-opacity': 1,
            labels: {
              attributes: {},
              'font-color': '#000000',
              'font-size': 12,
              'font-weight': 'normal',
              minzoom: 1,
              'offset-x': 0,
              'offset-y': 0,
              'stroke-color': '#000000',
              'stroke-visible': false,
              'stroke-width': 3,
            },
            'line-color': '#750E6B',
            'line-dash': [1, 5],
            'line-width': 2,
            minzoom: 1,
          },
          type: 'features_layer',
          visible: true,
        },
      ],
      name: 'Grupa warstw',
      type: 'group',
    },
  ],
  name: 'P1',
};

export { project };
