// At the moment, only default Open Layers coordinate systems are supported.
// If you need other coordinate system, you need to add proj4 and coordinate systems definitions.

const mapDataCoordinateSystem = {
  center: [2508710.1791630643, 7056054.543344613],
  epsg: 'EPSG:3857',
  srid: 3857,
  verboseName: 'Google Mercator',
};

const mapVerboseCoordinateSystem = {
  epsg: 'EPSG:4326',
  srid: 4326,
  verboseName: 'WGS84 (GPS)',
};

const mapDefaultZoom = 8;
const mapDefaultMinZoom = 0;
const mapDefaultMaxZoom = 28;

const appName = 'Invest in Podlaskie';
const appSubName = 'COI Podlaskie';
const defaultLanguage = 'pl';
const languages = ['pl', 'en', 'de', 'ru'];

export {
  mapDataCoordinateSystem,
  mapVerboseCoordinateSystem,
  mapDefaultZoom,
  mapDefaultMinZoom,
  mapDefaultMaxZoom,
  appName,
  appSubName,
  defaultLanguage,
  languages,
};
