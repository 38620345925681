import api from '@/services/api';
import Vue from 'vue';
import { make } from 'vuex-pathify';
import { arrayToObject, saveFileFromBackend } from '@/assets/js/utility';

const state = {
  dataSources: {},
  dataSourcesFeatures: {},
  dataSourcesFilteredFeaturesIds: {},
  layers: {},
  projects: {},
  projectLayersGeojsons: {},
};

const mutations = {
  ...make.mutations(state),
  SET_DATA_SOURCE_FEATURE(state, { dataSourceName, feature } = {}) {
    if (!state.dataSourcesFeatures[dataSourceName]) {
      state.dataSourcesFeatures = { ...state.dataSourcesFeatures, [dataSourceName]: {} };
    }
    Vue.set(
      state.dataSourcesFeatures[dataSourceName],
      feature.id,
      Object.freeze({
        ...feature,
        properties: { ...feature.properties, id: feature.id },
      })
    );
  },
  SET_PROJECTS(state, projects = []) {
    Vue.set(state, 'projects', Object.freeze(projects));
  },
  SET_PROJECT_LAYER_GEOJSON(state, { id, geojson }) {
    Vue.set(state.projectLayersGeojsons, id, Object.freeze(geojson));
  },
};

const actions = {
  async getDataSources({ commit }) {
    const r = await api.get('public/datasources/metadata');
    commit(
      'SET_DATA_SOURCES',
      arrayToObject(
        (r.data.data || [])
          .filter(dataSource => dataSource.scope === 'core')
          .map(dataSource => {
            return {
              name: dataSource.name,
              verboseName: dataSource.verbose_name,
              geometryType: dataSource.geometry_type,
              attributes: dataSource.attributes_schema.attributes,
            };
          }),
        'name'
      )
    );
  },
  async getDataSourceFeature({ commit }, { dataSourceName, featureId } = {}) {
    const r = await api.get(`public/datasources/${dataSourceName}/feature/${featureId}/geojson`);
    commit('SET_DATA_SOURCE_FEATURE', { dataSourceName, feature: r.data.data });
  },
  async getLayers({ commit }) {
    const r = await api.get('public/layers/metadata');
    commit(
      'SET_LAYERS',
      arrayToObject(
        r.data.data
          .filter(layer => layer.layer_scope === 'core')
          .map(layer => {
            return {
              id: layer.id,
              name: layer.name,
              dataSourceName: layer.data_source_name,
              attributes: layer.form_schema?.elements,
              imageAttribute: layer.image_attributes?.[0],
            };
          }),
        'id'
      )
    );
  },
  async getLayerFeatures({ commit }, { id } = {}) {
    const r = await api.get(`public/layers/${id}/geojson`);
    r.data.data?.features?.features.forEach(feature => {
      commit('SET_DATA_SOURCE_FEATURE', {
        dataSourceName: state.layers[id]?.dataSourceName,
        feature: { ...feature, crs: r.data.data.features.crs },
      });
    });
    commit('SET_PROJECT_LAYER_GEOJSON', { id, geojson: r.data.data?.features });
    return r.data.data?.features;
  },
  async getProjects({ commit }, projects = {}) {
    const r = await api.get('public/projects');
    commit(
      'SET_PROJECTS',
      Object.keys(projects).reduce((total, current) => {
        const currentProject = (r.data.data || []).find(project => project.g_id === projects[current]);
        if (!currentProject) return total;
        total[current] = {
          id: currentProject.g_id,
          layers: currentProject.layers,
          name: currentProject.name,
        };
        return total;
      }, {})
    );
  },
  async getProject(state, id) {
    const r = await api.get(`projects/${id}`);
    return r.data.data;
  },
  async getAttachments(store, { dataSourceName, featureId }) {
    return (await api.get(`attachments/${dataSourceName}/${featureId}`)).data.data;
  },
  async getSiteCheckList(store, { sclType, featureId, payload, fileName }) {
    const r = await api.post(`scl/${sclType}/${featureId}`, payload, { responseType: 'arraybuffer' });
    saveFileFromBackend(r.data, r.headers, fileName);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
