const arrayToObject = (array, key = 'id', value) => {
  return array.reduce((total, current) => {
    return { ...total, [current[key]]: value ? current[value] : current };
  }, {});
};

const capitalize = string => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const filterObjectKeys = (obj = {}, { allowedKeys, notAllowedKeys } = {}) => {
  return Object.keys(obj)
    .filter(key => {
      return (!allowedKeys || allowedKeys.includes(key)) && !(notAllowedKeys && notAllowedKeys.includes(key));
    })
    .reduce((rObj, key) => {
      rObj[key] = obj[key];
      return rObj;
    }, {});
};

const getHash = string => {
  let hash = 0,
    i,
    chr;
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};
const defaultHashGenerator = object => {
  return getHash(
    objectToArray(object)
      .map(value => {
        if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
          return objectToArray(value);
        }
        return value;
      })
      .join('')
  );
};
const memoizer = (f, { cacheObject, hashGenerator = defaultHashGenerator } = {}) => {
  const cache = cacheObject || {};
  return (argumentsObject = {}) => {
    const hashedArguments = hashGenerator(argumentsObject);
    if (cache[hashedArguments]) {
      return cache[hashedArguments];
    } else {
      const result = f(argumentsObject);
      cache[hashedArguments] = result;
      return result;
    }
  };
};

const objectToArray = (object, keyPropName) => {
  return Object.keys(object).map(key => {
    return keyPropName ? { ...object[key], [keyPropName]: key } : object[key];
  });
};

const sortObjectsByAttribute = (array, attribute, exclude, number = false) => {
  return array.sort((a, b) => {
    if (exclude && a[exclude.name] === exclude.value) {
      return -1;
    } else if (exclude && b[exclude.name] === exclude.value) {
      return 1;
    } else {
      return number
        ? b[attribute] - a[attribute]
        : a[attribute]?.toString().toLowerCase().localeCompare(b[attribute]?.toString().toLowerCase());
    }
  });
};

const saveFile = (blobUrl, name) => {
  const link = document.createElement('a');
  link.setAttribute('href', blobUrl);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
};
const saveFileFromBackend = (data, headers, name) => {
  const [type] = headers['content-type'].split(';');
  const blob = new Blob([data], { type });
  saveFile(URL.createObjectURL(blob), name);
};

export {
  arrayToObject,
  capitalize,
  filterObjectKeys,
  memoizer,
  objectToArray,
  sortObjectsByAttribute,
  saveFileFromBackend,
};
