import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context('@/locales', false, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const pluralizationRules = {
  de: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  en: choice => {
    return choice === 0 ? 0 : choice === 1 ? 1 : 2;
  },
  pl: choice => {
    return choice === 0
      ? 0
      : choice == 1
      ? 1
      : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)
      ? 2
      : 3;
  },
  ru: choice => {
    return choice === 0
      ? 0
      : choice % 10 === 1 && choice % 100 != 11
      ? 1
      : choice % 10 >= 2 && choice % 10 <= 4 && (choice % 100 < 10 || choice % 100 >= 20)
      ? 2
      : 3;
  },
};

const dateTimeFormat = {
  short: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  long: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    hour: 'numeric',
    minute: 'numeric',
  },
};
const dateTimeFormats = {
  en: dateTimeFormat,
  pl: dateTimeFormat,
};

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: loadLocaleMessages(),
  pluralizationRules,
  dateTimeFormats,
});
